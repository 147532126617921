 <template>
  <v-container class="bl_errorPage pa-0 ma-0">
    <v-tabs grow 
    height="70px"
    center-active color="grey" slider-color="primary" class="pt-2 bl_tabs">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact class="button text-subtitle-2 font-weight-bold primary--text"
      :class="{ 'active-class' : tab.id=='3'}"  style="flex-direction: column; align-items:center; justify-content: center; font-size:1rem !important; border-bottom: #efefef solid 3px;" disabled>
        {{ tab.name }}<br />
        <span v-if="tab.name=='建て方選択' && selectedHouseholdType" class="bl_tabs_tab_selected">{{selectedHouseholdType}}</span>
        <span v-if="tab.name=='報告方法選択' && selectedReportType" class="bl_tabs_tab_selected">{{selectedReportType}}</span>
        <div v-if="tab.id=='3'" class="uq_badgeError">エラー一覧</div>
      </v-tab>
    </v-tabs>

    <v-container class="px-8">
      <v-row class="pt-8 pb-4 px-4">
        <v-col cols="12" md="8" class="display:flex; flex-direction:column; align-end justify-center pl-0 mb-0">
          <p
            class="text-h5">エラーファイルの差し替え
          </p>
          <p class="mb-0">
            ファイル名：{{ errorResults[selectedIndex].originalName }}<br />
            <small>(PDFファイルの1ページ目だけを表示してます。)</small>
          </p>
          <p class="mb-0">
            エラー理由：
            <span class="red--text">{{ errorResults[selectedIndex].error_msg }} </span>
          </p>
          <p
            v-if="errorResults[selectedIndex].error_msg == '⼀次エネルギー消費量計算結果(住宅版)ではありません。'"
            class="mb-0 pl-20 red--text">
            「⼀次エネルギー消費量計算結果(住宅版)」であっても
            <span class="font-weight-bold">スキャン</span>
            や
            <span class="font-weight-bold">編集等</span>
            されたファイルは
            <br/>
            エラーファイルとなります。
          </p>
        </v-col>
        <v-col cols="12" md="4" class="pl-6 pr-0 pb-2 uq_sm_flex_row" style="display: flex; flex-direction: column; align-items: flex-end; justify-content:flex-end;">
          <v-btn
            @click="showReplaceDialog"
            width="250"
            height="36"
            color="#5E539A"
          >
            <span class="text-subtitle-2 font-weight-bold white--text">差し替えファイルのアップロード</span>
          </v-btn>
        </v-col>
      </v-row>
      
      <show-message/>

      <v-row class="canvas-container">
        <v-col
          class="canvas"
          cols=12
          align="center"
        >
          <pdf
            v-for="i in numPages"
            :key="i"
            :page="i"
            :src="pdfdata"
            :resize="true"
            class="page"
            height="auto"
          >
          </pdf>
        </v-col>
      </v-row>

      <v-row class="mx-0 mt-10 bl_fixedFooter">
        <v-btn
        @click="back"
        plain
        height="60"
        class="font-weight-bold text-h6 pl-0">
          <v-icon large>mdi-chevron-left</v-icon>
          戻る
        </v-btn>
      </v-row>
    </v-container>
    <!-- ファイル差し替えにダイアログボックス-->
    <v-dialog
      v-model="replaceDialog"
      persistent
      width="600"
    >
      <v-card
        min-height=300
      >
        <v-card-text
        class="pa-16 text-center font-weight-bold">
          <div
            v-if="replaceErrFileName"
            class="pb-2"
          >
            <span class="d-block">ファイル名：{{ replaceErrFileName}}</span>
            <span class="d-block">エラー理由: {{ replaceErrMsg }}</span>
          </div>
          <vue-dropzone ref="myVueDropzone" id="dropzone"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @vdropzone-success="fileUploaded"
            @vdropzone-error="fileUploadFailed"
            >
              「⼀次エネルギー消費量計算結果(住宅版)」PDFファイルを<br/>ここにドラッグ＆ドロップ または 
              <span class="text-decoration-underline">
                参照
              </span>
          </vue-dropzone>
          <v-btn
            @click="closeReplaceDialog"
            width="150"
            height="50"
            color="#5E539A"
            class="mt-6">
              <span class="white--text">{{ replaceErrFileName ? 'はい' : 'いいえ' }}</span>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div
      class="progress"
      v-show="progress"
    >
      <v-progress-circular
          indeterminate
          :size="150"
          :width="15"
          color="grey"
          class="circular"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
import pdfvuer from 'pdfvuer'
import { mapActions, mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ShowMessage from '@/components/ShowMessage.vue'
import { sleep, tabs } from '@/util'

export default {
  components: {
    pdf: pdfvuer,
    vueDropzone: vue2Dropzone,
    ShowMessage
  },

  data() {
    return {
      // ページ数
      numPages: 0,
      // 表示するpdfのデータ
      pdfdata: undefined,
      // dropzoneの設定
      dropzoneOptions:{
        url: '/api/upload',
        acceptedFiles: '.pdf',
        // 最大サイズ - 1MB
        maxFilesize : 1,
        uploadMultiple: true,
        includeStyling: false,
        previewsContainer: false,
        maxFiles: 1,
        // ファイルをserver側に送信する前の処理
        accept: this.acceptPdf,
      },
      // アップロード進行中表示の有無
      progress: false,
      // エラーファイル名
      errorFileName: '',
      // 差し替えダイアログボックス表示の有無
      replaceDialog: false,
      // 差し替えた不正なファイル名
      replaceErrFileName: '',
      // 差し替えた不正なファイルのエラーメッセージ
      replaceErrMsg: '',
      //エラー確認ページ用のナビゲーション
      tabs: tabs
    }
  },

  computed: {
    ...mapGetters([
      'isError',
      'selectedReportType',
      'errorResults',
      'selectedIndex',
      'selectedPdfSrc',
      'uploadedFileNames'
    ])
  },

  mounted () {
    // メッセージのクリア
    this.clearMessage()
    // pdfのプレビュー作成
    this.loadPdf()
  },

  methods:{
    ...mapActions([
      'update',
      'updateMessage',
      'clearMessage',
      'updateUploadedFileNames',
      'deleteUploadedFileName',
      'checkFile',
      'getCheckedResult',
      'checkJobStatus',
      'updateCheckedResult',
      'clearSelectedPdfSrc'
    ]),

    // pdfのプレビュー作成
    loadPdf() {
      let self = this
      self.pdfdata = pdfvuer.createLoadingTask(this.selectedPdfSrc)
      self.pdfdata.then(() => {
        self.numPages = 1
        // オブジェクトURLを解放する
        URL.revokeObjectURL(self.pdfdata)
      })
    },

     // ファイルをserver側に送信する前の処理
    acceptPdf(file, done) {
      this.progress = true
      // メッセージのクリア
      this.clearMessage()
      this.errorFileName = ''

      // ファイルの重複チェック
      const index = this.uploadedFileNames.findIndex((fileName) => file.name == fileName)
      if (index > -1) {
        this.errorFileName = file.name
        done('duplicate')
      } else {
        done()
      }
    },

    // ファイルアップロード成功する場合の処理
    async fileUploaded(file, response) {

      if (response.uploadStatus === 'success') {
        const isReplace = true
        const jobId = await this.checkFile(isReplace)

        if (!this.isError) {
          // job実行結果の確認
          const processingStatus = ['unknown','processing']
          let result = ''
          do {
              let response = await this.checkJobStatus(jobId)
              result = response['job_status']

              if (processingStatus.includes(result)) {
                  // 3秒待ち
                  await sleep(3000)
              }
          } while (processingStatus.includes(result))

          this.progress = false

          // job実行結果の取得
          if (result == 'finished') {
              const checkedResult = await this.getCheckedResult(jobId)

              if (!this.isError) {
                const chkObj = checkedResult[0]

                // 差し替えファイルが正しい場合
                if (chkObj.error_msg == '') {
                  // 成功メッセージ表示
                  const bfReplaceName = this.errorResults[this.selectedIndex].originalName
                  this.updateMessage({
                    message: bfReplaceName + "ファイルから" + file.name + "ファイルに差替えました。",
                    type: 'success'
                  })

                  // アップロードされたファイル名から削除する
                  this.deleteUploadedFileName(bfReplaceName)
                  // アップロードされたファイル名を更新する
                  this.updateUploadedFileNames(file.name)

                  // チェック結果を更新する
                  this.updateCheckedResult({checkedResult, deleteIndex : this.selectedIndex})
                  this.update({selectedIndex : -1})
                  this.$router.push('/error-result')
                } else {
                  // 差し替えファイルがエラーの場合
                  this.replaceErrFileName = file.name
                  this.replaceErrMsg = chkObj.error_msg
                }
              }
              this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
          } else {
              this.$refs.myVueDropzone.dropzone.removeAllFiles(true)
              this.updateMessage({
                message : "ファイルのチェックができませんでした。アップロードしたファイルを確認してください。",
                type: 'error'
              })
          }
        }
      }
    },

    // ファイル読み込み失敗の処理
    fileUploadFailed(file, message, xhr) {
      this.progress = false
      this.closeReplaceDialog()
      this.$refs.myVueDropzone.dropzone.removeAllFiles(true)

      // サーバエラー
      if (xhr) {
        let errMsg = ''

        if (xhr.status == 500) {
          errMsg = 'エラーが発生しました。しばらくしてからもう一度お試しください。'
        } else {
          errMsg = message
        }

        this.updateMessage({
          message : errMsg,
          type: 'error'
        })
      }

      // 最大サイズエラー
      if (message.includes('File is too big')) {
        this.updateMessage({
          message : "アップロードできるファイルは1MB以下のPDFファイルのみです。ファイルをご確認ください。" + file.name,
          type: 'error'
        })
      }

      // 重複エラー
      if (message == 'duplicate') {
        this.updateMessage({
          message : "ファイル名が重複しています。ご確認ください。" + this.errorFileName,
          type: 'error'
        })
      }
    },

    // 差し替えダイアログボックスを表示する
    showReplaceDialog() {
      this.replaceDialog = true
      this.clearMessage()
    },

    // 差し替えダイアログボックスをクローズする
    closeReplaceDialog() {
      this.replaceDialog = false
      this.replaceErrFileName = ''
      this.replaceErrMsg = ''
    },

    // エラー一覧画面へ遷移
    back() {
      this.clearMessage()
      this.clearSelectedPdfSrc()
      this.$router.push('/error-result')
    }

  }
}

</script>

<style scoped>
.canvas-container {
  display: flex;
  position: relative;
  min-height: calc(100vh - 300px);
  max-height: 500px;
  background-color: #F5F7F9;
}
.canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}
.page {
  width: 100%;
  box-shadow: 0 0 10px rgb(105, 104, 104);
  margin-bottom: 1em;
}
</style>